<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "vue2-datepicker";

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Schedule registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      info: null,
      title: "Schedule registration",
      componentKey: 0,
      num_latitude: 0.0,
      num_longitude: 0.0,
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Schedules",
          href: "/management/schedules"
        },
        {
          text: "Schedule",
          active: true
        }
      ],
      weekDays: ["MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"],
      form: {
        dt_fromTime: "",
        dt_toTime: "",
        num_weekDays: [],
      },
      submitted: false,
      validToken : true,
    };
  },
  validations: {
    form: {
      dt_fromTime:  { required },
      dt_toTime:    { required },
      num_weekDays: { required }
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if( this.$route.params.id ){
      this.getScheduleByID();
    }
  },
  methods: {
    getScheduleByID(){
      Swal.fire({
        title: "Loading schedule data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;

      axios
      .get(
        window.serverPath+'/dist/mvw/controllers/schedules.php',
        {
          params: {
            request: 'getScheduleByID',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          app.site = response.data;

          this.form.dt_fromTime    = app.site.dt_fromTime;
          this.form.dt_toTime      = app.site.dt_toTime;
          this.form.num_weekDays   = app.site.num_weekDays;
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveSchedule(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_schedule = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving schedule.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'/dist/mvw/controllers/schedules.php', {
          request: 'saveSchedule',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Your schedule is correct "+action+"!", "success");
          app.$router.push({ name: 'schedules' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
    consoleThis(){
      console.log(this.form.num_weekDays);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveSchedule">
              <div class="row">
                <div class="col-sm-12">
                  <h4 class="card-title">Schedule Data</h4>
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>From</label>
                        <date-picker
                          v-model="form.dt_fromTime"
                          format="hh:mm:ss a"
                          value-type="format"
                          type="time"
                          placeholder="hh:mm:ss a"
                        ></date-picker>
                      </div>

                      <div class="form-group">
                        <label>To</label>
                        <date-picker
                          v-model="form.dt_toTime"
                          format="hh:mm:ss a"
                          value-type="format"
                          type="time"
                          placeholder="hh:mm:ss a"
                        ></date-picker>
                      </div>
                      
                      <div class="form-group">
                        <label for="num_weekDays">Days</label>
                        <select
                          multiple
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.num_weekDays.$error }"
                          name="num_weekDays"
                          id="num_weekDays"
                          v-model="form.num_weekDays"
                          @change="consoleThis"
                          size="7"
                        >
                          <option v-for="(day, index) in weekDays" :key="index" :value="index">{{day}}</option>
                        </select>
                        <div v-if="submitted && $v.form.num_weekDays.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_weekDays.required">Please choose the day(s) for this schedule.</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE SCHEDULE</button>
                    <router-link to="/schedules" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
          </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>